<template>
  <div>
    <b-sidebar
      id="sidebar-create-consultant"
      sidebar-class="sidebar-xg"
      :visible="newConsultantSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateNewConsultantSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4 v-if="newConsultantSidebar.id == null">Novo PN</h4>
          <h4 v-else-if="newConsultantSidebar.customer_id">
            Transformar em PN
          </h4>
          <h4 v-else>Editar PN</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group label="Nome" label-for="consultant-name">
                <b-form-input
                  id="consultant-name"
                  v-model="name"
                  :class="{ 'is-invalid': v$.name.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.name.$invalid">
                    Você deve informar um nome
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <label for="consultant-email" class="mt-1"> E-mail Futuro </label>
              <b-input-group
                append="@futuropn.com.br"
                id="consultant-email-group"
                :class="{ 'is-invalid': v$.email.$error }"
              >
                <b-form-input id="consultant-email" v-model="email" />
              </b-input-group>
              <div class="invalid-feedback">
                <span v-if="v$.email.$invalid">
                  Você deve informar somente o prefixo do email no formato
                  nome.sobrenome
                </span>
              </div>
            </b-col>
            <b-col cols="12">
              <b-form-group label="CPF" label-for="cpf" class="mt-1">
                <b-form-input
                  id="cpf"
                  v-mask="'###.###.###-##'"
                  v-model="cpf"
                  :class="{ 'is-invalid': v$.cpf.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.cpf.$invalid">
                    O CPF informado é inválido
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Celular"
                label-for="consultant-cellphone"
                class="mt-1"
              >
                <vue-tel-input
                  id="consultant-cellphone"
                  v-model="cellphone"
                  :class="{ 'is-invalid': v$.cellphone.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.cellphone.$invalid">
                    Você deve informar um telefone válido
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="E-mail pessoal"
                label-for="consultant-contact-email"
                class="mt-1"
              >
                <b-form-input
                  id="consultant-contact-email"
                  v-model="contactEmail"
                  :class="{ 'is-invalid': v$.contactEmail.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.contactEmail.$invalid">
                    Você deve informar um email válido
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Estado"
                label-for="addressState"
                class="mt-1"
              >
                <v-select
                  id="addressState"
                  v-model="addressState"
                  :reduce="(addressStates) => addressStates.id"
                  :options="addressStates"
                  label="name"
                  :class="getSelectErrorClass(v$.addressState.$error)"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
                <div class="invalid-feedback">
                  <span v-if="v$.addressState.$invalid">
                    Você deve informar um estado
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Cidade" label-for="addressCity" class="mt-1">
                <v-select
                  id="addressCity"
                  v-model="addressCity"
                  :reduce="(addressCities) => addressCities.id"
                  :options="addressCities"
                  label="name"
                  :class="getSelectErrorClass(v$.addressCity.$error)"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
                <div class="invalid-feedback">
                  <span v-if="v$.addressCity.$invalid">
                    Você deve informar uma cidade
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Formação"
                label-for="schoolLevel"
                class="mt-1"
              >
                <v-select
                  id="schoolLevel"
                  v-model="schoolLevel"
                  :reduce="(schoolLevels) => schoolLevels.id"
                  :options="schoolLevels"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Objetivo" label-for="goal" class="mt-1">
                <v-select
                  id="goal"
                  v-model="goal"
                  :reduce="(goals) => goals.key"
                  :options="goals"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Já empreendeu?"
                label-for="wasEntrepreneur"
                class="mt-1"
              >
                <v-select
                  id="wasEntrepreneur"
                  v-model="wasEntrepreneur"
                  :reduce="(option) => option.value"
                  :options="assertBooleanOptions"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Origem" label-for="leadOrigin" class="mt-1">
                <v-select
                  id="leadOrigin"
                  v-model="leadOrigin"
                  :reduce="(origins) => origins.key"
                  :options="leadOrigins"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group
                label="Data de nascimento"
                label-for="user-birth-date"
                class="mt-1"
              >
                <b-form-input
                  id="user-birth-date"
                  v-model="birthDate"
                  v-mask="'##/##/####'"
                  :class="{ 'is-invalid': v$.birthDate.$error }"
                />
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.birthDate.minLength.$invalid || v$.birthDate.$invalid
                    "
                  >
                    A data informada é inválida
                  </span>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" v-if="!approvalDateFromRecruitment">
              <b-form-group
                label="Data de aprovação"
                label-for="approvalDate"
                class="mt-1"
              >
                <v-select
                  id="approvalDate"
                  v-model="approvalDate"
                  :reduce="(approvalDates) => approvalDates.approval_date"
                  :options="approvalDates"
                  label="approval_date_label"
                  :class="getSelectErrorClass(v$.approvalDate.$error)"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
                <div class="invalid-feedback">
                  <span v-if="v$.approvalDate.$invalid">
                    Você deve informar uma data válida
                  </span>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" v-if="isAdm">
              <b-form-group label="Escritório" label-for="physicalFranchise">
              <v-select
                id="physicalFranchise"
                v-model="physicalFranchise"
                :reduce="(physical_franchise_list) => physical_franchise_list.id"
                :options="physicalFranchises"
                :loading="loading.physicalFranchises"
                :close-on-select="false"
                label="name"
                @input="onPhysicalFranchiseChange"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12" v-if="showChangeFields">
              <b-form-group label="Data Programada para a Mudança" label-for="change-date">
                <b-form-input
                  id="change-date"
                  type="date"
                  v-model="changeDate"
                  :required="showChangeFields"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="showChangeFields">
              <b-form-group label="Motivo da Mudança" label-for="change-reason">
                <b-form-textarea
                  id="change-reason"
                  v-model="changeReason"
                  :required="showChangeFields"
                  rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-col>

          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-between">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="hide"
              class="consultant-buttons"
            >
              Voltar
            </b-button>
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="consultant-buttons"
            >
              {{ saving ? "Salvando..." : "Salvar" }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormInput,
  BInputGroup,
  BCol,
  BRow,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength } from "@vuelidate/validators";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import { onlyDigits, dateToString, stringToDate } from "@/helpers/converters";
import cpfIsValid from "@/validators/cpf";
import { getVueSelectErrorClass } from "@/helpers/validators";
import { assertBooleanOptions } from "@/constants/assertions";
import { LEAD_TYPE_PPN } from "@/constants/lead";
import { USER } from '@/modules/account/store/types'
import { isBackofficeRole } from "@/constants/auth";

const alphaNameDotName = helpers.regex(/^[a-z]+\.[a-z]+$/);
const alphaEmail = helpers.regex(
  /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
);

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BCol,
    BRow,
    vSelect,
    DynamicSelect,
    BInputGroup,
  },
  props: {
    callback: Function,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      assertBooleanOptions,
      loading: {
        addressStates: false,
        addressCities: false,
        schoolLevels: false,
        goals: false,
        leadOrigins: false,
        physicalFranchises: false,
      },
      name: undefined,
      email: undefined,
      cpf: undefined,
      cellphone: undefined,
      contactEmail: undefined,
      approvalDate: undefined,
      addressState: undefined,
      addressCity: undefined,
      schoolLevel: undefined,
      goal: undefined,
      wasEntrepreneur: undefined,
      leadOrigin: undefined,
      saving: false,
      birthDate: undefined,
      physicalFranchise: undefined,
      originalPhysicalFranchise: null,
      showChangeFields: false,
      changeDate: null,
      changeReason: "",
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      name: { required },
      cellphone: {
        minLength(value) {
          return value.length >= 9;
        },
        maxLength(value) {
          return value.length <= 20;
        },
      },
      email: {
        required,
        alphaNameDotName,
      },
      cpf: {
        required,
        valid(value) {
          return !value || cpfIsValid(value);
        },
      },
      contactEmail: {
        required,
        alphaEmail,
      },
      approvalDate: { required },
      addressState: { required },
      addressCity: { required },
      schoolLevel: {},
      goal: {},
      wasEntrepreneur: {},
      leadOrigin: {},
      birthDate: {
        required,
        minLength: minLength(10),
      },
      physicalFranchise: {},
    };
  },
  computed: {
    ...mapGetters({
      newConsultantSidebar: types.NEW_CONSULTANT_SIDEBAR,
      consultantApprovalDates: sharedTypes.CONSULTANT_APPROVAL_DATES,
      consultantFutureApprovalDates:
        sharedTypes.CONSULTANT_FUTURE_APPROVAL_DATES,
      addressStates: sharedTypes.ADDRESS_STATES,
      addressCities: sharedTypes.ADDRESS_CITIES,
      goals: sharedTypes.LEAD_GOALS,
      schoolLevels: sharedTypes.SCHOOL_LEVELS,
      leadOrigins: sharedTypes.LEAD_ORIGINS,
      physicalFranchises: sharedTypes.PHYSICAL_FRANCHISES,
      user: USER
    }),
    isAdm: function () {
      return isBackofficeRole(this.user?.user_role_id);
    },
    approvalDates() {
      if (this.newConsultantSidebar.id) {
        return _.map(this.consultantApprovalDates, (approvalDateObject) => ({
          approval_date: approvalDateObject.approval_date,
          approval_date_label: this.$options.filters.onlyDate(
            approvalDateObject.approval_date
          ),
        }));
      } else {
        return _.map(
          this.consultantFutureApprovalDates,
          (futureApprovalDateObject) => ({
            approval_date: futureApprovalDateObject.approval_date,
            approval_date_label: this.$options.filters.onlyDate(
              futureApprovalDateObject.approval_date
            ),
          })
        );
      }
    },
    approvalDateFromRecruitment() {
      return this.newConsultantSidebar.approval_date;
    },
  },
  mounted() {
    this.getConsultantApprovalDates();
    this.getConsultantFutureApprovalDates();
    this.loading.addressStates = true;
    this.getAddressStates()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os estados para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.addressStates = false;
      });
    this.loading.addressCities = true;
    this.getAddressCities()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as cidades para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.addressCities = false;
      });
    this.loading.schoolLevels = true;
    this.getSchoolLevels()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as formações para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.schoolLevels = false;
      });
    this.loading.goals = true;
    this.getLeadGoals({ leadType: LEAD_TYPE_PPN })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os objetivos para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.goals = false;
      });
    this.getLeadOrigins({ leadType: LEAD_TYPE_PPN })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as origens do lead para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.leadOrigins = false;
      });
    if (this.isAdm) {
      this.loading.physicalFranchises = true;
      this.getPhysicalFranchises()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as franquias físicas para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.physicalFranchises = false
        });
    }
  },
  methods: {
    ...mapMutations({
      mutateNewConsultantSidebar: types.MUTATE_NEW_CONSULTANT_SIDEBAR,
    }),
    ...mapActions({
      updateConsultant: types.SAVE_CONSULTANT_BASIC_DATA,
      saveConsultant: types.SAVE_CONSULTANT,
      getConsultant: types.GET_CONSULTANT,
      getConsultantApprovalDates: sharedTypes.GET_CONSULTANT_APPROVAL_DATES,
      getConsultantFutureApprovalDates:
        sharedTypes.GET_CONSULTANT_FUTURE_APPROVAL_DATES,
      getLeadByCustomer: sharedTypes.GET_LEAD_BY_CUSTOMER,
      getLeadOrigins: sharedTypes.GET_LEAD_ORIGINS,
      getAddressStates: sharedTypes.GET_ADDRESS_STATES,
      getAddressCities: sharedTypes.GET_ADDRESS_CITIES,
      getSchoolLevels: sharedTypes.GET_SCHOOL_LEVELS,
      getLeadGoals: sharedTypes.GET_LEAD_GOALS,
      getPhysicalFranchises: sharedTypes.GET_PHYSICAL_FRANCHISES,
    }),
    onShow() {
      this.saving = false;
      if (this.newConsultantSidebar.id) {
        this.getConsultant(this.newConsultantSidebar.id)
          .then((resp) => {
            var data = resp.data;
            if (data) {
              this.name = data.name;
              this.email = data.email.split("@")[0];
              this.cellphone = data.cell_phone ?? undefined;
              this.cpf = data.doc_cpf;
              this.contactEmail = data.personal_email;
              this.approvalDate = data.approval_date ?? undefined;
              this.addressState = data.address_state_id ?? undefined;
              this.addressCity = data.address_state_id ?? undefined;
              this.schoolLevel = data.school_level_id ?? undefined;
              this.goal = data.lead_goal ?? undefined;
              this.wasEntrepreneur = data.was_entrepreneur ? true : false;
              this.leadOrigin = data.lead_origin ?? undefined;
              this.birthDate = dateToString(data.birth_date) ?? undefined;
              this.physicalFranchise = data.consultant_network.physical_franchise_id ?? undefined;
              this.originalPhysicalFranchise = this.physicalFranchise;
              this.v$.$touch();
            }
          })
          .catch((err) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar o consultor para edição. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
      if (this.newConsultantSidebar.customer_id) {
        this.getLeadByCustomer(this.newConsultantSidebar.customer_id)
          .then((resp) => {
            var data = resp.data;
            if (data) {
              this.name = data.name;
              this.cellphone = data.cell_phone ?? undefined;
              this.contactEmail = data.email;
              this.addressState = data.address_state_id ?? undefined;
              this.addressCity = data.address_city_id ?? undefined;
              this.schoolLevel = data.school_level_id ?? undefined;
              this.goal = data.goal ?? undefined;
              this.wasEntrepreneur = data.was_entrepreneur ? true : false;
              this.leadOrigin = data.origin ?? undefined;
              this.physicalFranchise = data.consultant.consultant_network.physical_franchise_id ?? undefined;
              this.v$.$touch();
            }
          })
          .catch((err) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar os dados do lead. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
        this.approvalDate = this.newConsultantSidebar.approval_date;
      }
    },
    onPhysicalFranchiseChange(newValue) {
      if (this.newConsultantSidebar.id) {
        if (newValue !== this.originalPhysicalFranchise) {
          this.showChangeFields = true;
        } else {
          this.showChangeFields = false;
          this.changeDate = null;
          this.changeReason = "";
        }
      }
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const { name, email } = this;
      const payload = {
        id: this.newConsultantSidebar.id,
        customer_id: this.newConsultantSidebar.customer_id,
        name,
        email,
        cpf: onlyDigits(this.cpf),
        cell_phone: onlyDigits(this.cellphone),
        personal_email: this.contactEmail,
        approval_date: this.approvalDate,
        address_state_id: this.addressState,
        address_city_id: this.addressCity,
        school_level_id: this.schoolLevel,
        lead_goal: this.goal,
        lead_origin: this.leadOrigin,
        was_entrepreneur: this.wasEntrepreneur,
        birth_date: stringToDate(this.birthDate),
        physical_franchise: this.physicalFranchise,
        change_date: this.changeDate,
        change_reason: this.changeReason,
      };
      if (this.newConsultantSidebar.id) {
        this.updateConsultant(payload)
          .then((response) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso",
                text: "Consultor salvo com sucesso!",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.mutateNewConsultantSidebar({ visible: false });
            if (this.callback) {
              this.callback();
            }
          })
          .catch((err) => {
            if (err?.response?.status == 405) {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: err.response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            } else {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao salvar o consultor. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          })
          .finally(() => {
            this.saving = false;
          });
      } else {
        this.saveConsultant(payload)
          .then((response) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso",
                text: "PN salvo com sucesso! Contrato enviado para o email pessoal!",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.mutateNewConsultantSidebar({ visible: false });
            this.$router.push({ name: "consultants-list" });
            if (this.callback) {
              this.callback();
            }
          })
          .catch((err) => {
            if (err?.response?.status == 405) {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: err.response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            } else {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao salvar o consultor. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
    clear() {
      this.name = undefined;
      this.email = undefined;
      this.cellphone = undefined;
      this.contactEmail = undefined;
      this.addressState = undefined;
      this.addressCity = undefined;
      this.schoolLevel = undefined;
      this.goal = undefined;
      this.wasEntrepreneur = undefined;
      this.leadOrigin = undefined;
      this.approvalDate = undefined;
      this.birthDate = undefined;
      this.showChangeFields = false;
      this.changeDate = null; 
      this.changeReason = "";
      this.v$.$reset();
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-tel-input {
  padding: 0.438rem 1rem;
}
</style>
<style lang="scss">
.sidebar-xg {
  width: 36rem;
}

.consultant-buttons {
  width: 10rem;
}
</style>
